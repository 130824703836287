import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from '@remix-run/react';
import { withSentry } from '@sentry/remix';
import '@synoptic/ui-kit/normalize';
import { themeClass } from '@synoptic/ui-kit/theme.css.js';
import { ToastProvider } from '@synoptic/ui-kit/toast/toast-provider.js';
import { makeToastQueue } from '@synoptic/ui-kit/toast/toast-queue.js';
import { Toaster } from '@synoptic/ui-kit/toast/toaster.js';
import { ErrorPage } from './components/error-page/error-page.tsx';
import './entry.css.ts';
import {
  AWS_COGNITO_USER_POOL_PROVIDER_URL,
  AWS_GRAPHQL_API_URL,
  AWS_S3_BUCKET_NAME,
} from './env.ts';
import { PageViewTracking } from './page-view-tracking.tsx';
import { getPageMeta } from './routes-utils/get-page-meta.ts';

const toastQueue = makeToastQueue();

export const ErrorBoundary = () => <ErrorPage withContactUs />;

export const meta = () => getPageMeta();

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <html lang="en" className={themeClass}>
      <head>
        <meta charSet="utf-8" />
        {import.meta.env.MODE !== 'production' ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : null}
        <link rel="icon" href="/static/favicon.ico" sizes="32x32" />
        <link
          id="svgIcon"
          rel="icon"
          href={`/static/icon-${import.meta.env.MODE || 'development'}.svg`}
          type="image/svg+xml"
        />
        <link rel="apple-touch-icon" href="/static/apple-touch-icon.png" />
        <link rel="manifest" href="/static/manifest.json" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0,viewport-fit=cover"
        />

        <link
          rel="preconnect"
          href="https://cognito-identity.us-east-1.amazonaws.com"
        />
        <link rel="preconnect" href={new URL(AWS_GRAPHQL_API_URL).origin} />
        <link
          rel="preconnect"
          href={new URL(AWS_COGNITO_USER_POOL_PROVIDER_URL).origin}
        />
        <link rel="preconnect" href={`https://${AWS_S3_BUCKET_NAME}`} />

        <Meta />
        <Links />

        {/* Google tag (gtag.js) */}
        {import.meta.env.MODE === 'production' ? (
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-Z3WZB940CS"
          ></script>
        ) : null}
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());

            gtag('config', 'G-Z3WZB940CS', { send_page_view: false });
          `,
          }}
        ></script>
      </head>
      <body>
        <script
          // hack for https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/426
          dangerouslySetInnerHTML={{
            __html: `
          if (global === undefined) {
            var global = window;
          }
          `,
          }}
        ></script>
        <div id="root">
          {/* TODO: probably better to wrap only layouts where it's needed */}
          <ToastProvider toastQueue={toastQueue}>
            {children}
            <Toaster />
          </ToastProvider>

          <ScrollRestoration />
          <PageViewTracking />
          <Scripts />
        </div>
      </body>
    </html>
  );
};

const Root = withSentry(function RootComponent() {
  return <Outlet />;
});

export default Root;
