type Listener = () => void;

export const makePubSub = () => {
  let listeners: Listener[] = [];

  const unsubscribe = (listener: Listener) => {
    listeners = listeners.filter((l) => l !== listener);
  };

  const subscribe = (listener: Listener) => {
    listeners = [listener, ...listeners];

    return () => unsubscribe(listener);
  };

  const publish = () => {
    listeners.forEach((l) => l());
  };

  return {
    subscribe,
    unsubscribe,
    publish,
  };
};

export type PubSub = ReturnType<typeof makePubSub>;
