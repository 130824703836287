import { useEffect, useRef } from 'react';
import { CheckIcon } from '../icons/react/check';
import { WarningTriangleIcon } from '../icons/react/warning-triangle';
import { useCallbackRef } from '../utils/use-callback-ref';
import { useControllableState } from '../utils/use-controllable-state';
import { ToastClose, ToastRoot, ToastSubtitle, ToastTitle } from './primitives';
import type { ToastActionElement } from './toast-action';
import { toastActions, toastIcon, toastMessage, toastRoot } from './toast.css';

type ToastType = 'info' | 'error' | 'success';

export type ToastProps = {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  icon?: React.ReactNode;
  action?: ToastActionElement;
  duration?: number;
  dismissable?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;

  type?: ToastType;

  /** It gets called after exit animation */
  onHide?: () => void;
};

const defaultPropsPerType: Record<ToastType, Partial<ToastProps>> = {
  info: {},
  error: {
    icon: <WarningTriangleIcon width={24} height={24} />,
    duration: 10000,
    dismissable: true,
  },
  success: {
    icon: <CheckIcon width={24} height={24} />,
  },
};

export const Toast: React.FC<ToastProps> = (props) => {
  const defaultProps = defaultPropsPerType[props.type || 'info'];

  const {
    title,
    subTitle,
    icon,
    action,
    duration = 5000,
    dismissable,
    open,
    onOpenChange,
    onHide,
  } = {
    ...defaultProps,
    ...props,
  };

  const [isOpen, setOpen] = useControllableState({
    value: open,
    defaultValue: true,
    onChange: onOpenChange,
  });

  const ref = useRef<HTMLLIElement>(null);
  const onHideCb = useCallbackRef(onHide);

  useEffect(() => {
    const toastEl = ref.current;
    if (!isOpen) {
      const emergencyTimeout = setTimeout(onHideCb, 400); // just in case if animationend doesn't fire for some reasons or toast got closed before render

      toastEl?.addEventListener('animationend', onHideCb, {
        // https://github.com/radix-ui/primitives/issues/1020
        capture: true,
      });

      return () => {
        toastEl?.removeEventListener('animationend', onHideCb, {
          capture: true,
        });
        clearTimeout(emergencyTimeout);
      };
    }

    return () => {};
  }, [isOpen, onHideCb]);

  const close = dismissable ? <ToastClose /> : null;

  return (
    <ToastRoot
      ref={ref}
      open={open}
      duration={duration}
      className={toastRoot}
      onOpenChange={setOpen}
    >
      {icon ? <div className={toastIcon}>{icon}</div> : null}

      <div className={toastMessage}>
        <ToastTitle>{title}</ToastTitle>
        {subTitle ? <ToastSubtitle>{subTitle}</ToastSubtitle> : null}
      </div>

      {action || close ? (
        <div className={toastActions}>
          {action}
          {close}
        </div>
      ) : null}
    </ToastRoot>
  );
};
