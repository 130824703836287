declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: any;
  }
}

export const setGtagProperties = (
  path: string,
  search: string,
  hash: string,
) => {
  window.gtag('event', 'page_view', {
    page_path: path + search + hash,
    page_search: search,
    page_hash: hash,
  });
};
