import { makePubSub } from '../utils/pub-sub';
import type { ToastProps } from './toast';

export type AddToastProps = Pick<
  ToastProps,
  'type' | 'title' | 'subTitle' | 'icon' | 'action' | 'duration' | 'open'
> & {
  id?: string;
};
type TypedAddProps = Omit<AddToastProps, 'type'>;

type QueuedToast = AddToastProps & {
  id: string;
};

type ToastQueueOpts = {
  maxActiveToasts?: number;
};
export const makeToastQueue = ({
  maxActiveToasts = 1,
}: ToastQueueOpts = {}) => {
  const pubSub = makePubSub();
  let queue: QueuedToast[] = [];
  let activeToasts: QueuedToast[] = [];

  let count = 0;

  const makeId = () => {
    return `toast-${count++}`;
  };

  const updateActiveToasts = () => {
    const freeSlots = Math.max(maxActiveToasts - activeToasts.length, 0);

    const newToasts = queue.slice(0, freeSlots);
    queue = queue.slice(freeSlots);

    activeToasts = activeToasts.concat(newToasts);

    pubSub.publish();
  };

  /**
   * Add toast to queue
   *
   * Pass custom `id` if you don't want toasts to be duplicated.
   * If toast with same id already exists it will be closed to display fresh more relevant one
   */
  const add = ({ id, ...toast }: AddToastProps) => {
    const toastToClose =
      (id && activeToasts.find((t) => t.id === id)) ||
      (activeToasts.length === maxActiveToasts && activeToasts[0]);

    if (toastToClose) {
      toastToClose.open = false;
    }

    const t: QueuedToast = {
      id: id || makeId(),
      ...toast,
    };

    queue.push(t);

    updateActiveToasts();

    return t.id;
  };

  return {
    add,
    /** Mark active toast as closed. Can be used to play exit animation  */
    close(id: string) {
      activeToasts = activeToasts.map((t) =>
        t.id === id ? { ...t, open: false } : t,
      );

      pubSub.publish();
    },
    /** remove toast from active ones, should be called after exit animation  */
    remove(id: string) {
      activeToasts = activeToasts.filter((t) => t.id !== id);

      updateActiveToasts();
    },
    getActiveToasts() {
      return activeToasts;
    },
    subscribe: pubSub.subscribe,
    unsubscribe: pubSub.unsubscribe,

    info(t: TypedAddProps) {
      return add(t);
    },
    success(t: TypedAddProps) {
      return add({
        type: 'success',
        ...t,
      });
    },
    error(t: TypedAddProps) {
      return add({
        type: 'error',
        ...t,
      });
    },
  };
};

export type ToastQueue = ReturnType<typeof makeToastQueue>;
