import { useSyncExternalStore } from 'react';
import { ToastViewport } from './primitives';
import { Toast } from './toast';
import { useToast } from './toast-provider';

const viewportId = 'toast-viewport';

export const Toaster: React.FC = () => {
  const toastQueue = useToast();

  const toasts = useSyncExternalStore(
    toastQueue.subscribe,
    toastQueue.getActiveToasts,
    toastQueue.getActiveToasts,
  );

  return (
    <>
      {toasts.map(({ id, open, type = 'info', ...toastProps }) => (
        <Toast
          key={`${id}-${type}`}
          type={type}
          {...toastProps}
          open={open}
          onHide={() => {
            // workaround for https://github.com/radix-ui/primitives/issues/2233
            // without it timers aren't resumed until hover if the last toast was closed via ToastAction
            if (toasts.length === 1) {
              document.getElementById(viewportId)?.blur();
            }
            toastQueue.remove(id);
          }}
        />
      ))}

      <ToastViewport id={viewportId} />
    </>
  );
};
