import { useLocation } from '@remix-run/react';
import { useEffect } from 'react';
import { setGtagProperties } from './utils/set-gtag-properties';

export const PageViewTracking = () => {
  const location = useLocation();

  useEffect(() => {
    setGtagProperties(location.pathname, location.search, location.hash);
  }, [location]);

  return null;
};
