import * as ToastPrimitives from '@radix-ui/react-toast';
import { ElementRef, forwardRef } from 'react';
import { IconButton } from '../button/icon-button';
import { XmarkIcon } from '../icons/react/xmark';
import clsx from 'clsx';
import {
  toastPrimitiveClose,
  toastPrimitiveRoot,
  toastPrimitiveSubtitle,
  toastPrimitiveTitle,
  toastPrimitiveViewport,
} from './primitives.css';

const preventDefault = (e: Event) => e.preventDefault();

export const ToastViewport = forwardRef<
  ElementRef<typeof ToastPrimitives.Viewport>,
  ToastPrimitives.ToastViewportProps
>(function ToastViewport({ className, ...props }, ref) {
  return (
    <ToastPrimitives.Viewport
      ref={ref}
      className={clsx(toastPrimitiveViewport, className)}
      {...props}
    />
  );
});

export const ToastRoot = forwardRef<
  ElementRef<typeof ToastPrimitives.Root>,
  ToastPrimitives.ToastProps
>(function ToastRoot({ className, ...props }, ref) {
  return (
    <ToastPrimitives.Root
      ref={ref}
      className={clsx(toastPrimitiveRoot, className)}
      onSwipeStart={preventDefault}
      onSwipeMove={preventDefault}
      onSwipeCancel={preventDefault}
      onSwipeEnd={preventDefault}
      {...props}
    />
  );
});

export const ToastTitle = forwardRef<
  ElementRef<typeof ToastPrimitives.Title>,
  ToastPrimitives.ToastTitleProps
>(function ToastTitle({ className, ...props }, ref) {
  return (
    <ToastPrimitives.Title
      ref={ref}
      className={clsx(className, toastPrimitiveTitle)}
      {...props}
    />
  );
});

export const ToastSubtitle = forwardRef<
  ElementRef<typeof ToastPrimitives.Description>,
  ToastPrimitives.ToastDescriptionProps
>(function ToastSubtitle({ className, ...props }, ref) {
  return (
    <ToastPrimitives.Description
      ref={ref}
      className={clsx(className, toastPrimitiveSubtitle)}
      {...props}
    />
  );
});

export const ToastClose = () => {
  return (
    <ToastPrimitives.Close aria-label="Dismiss" asChild>
      <IconButton className={toastPrimitiveClose} size="medium">
        <XmarkIcon />
      </IconButton>
    </ToastPrimitives.Close>
  );
};
